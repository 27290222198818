import type { Options } from '@contentful/rich-text-react-renderer';
import type { Block, Inline } from '@contentful/rich-text-types';

import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import Image from 'next/image';
import Link from 'next/link';

import { type LinkedImage, type Asset, type Cta } from 'gql/queries/generated';
import EmbeddableCTA from 'components/EmbeddableCTA/EmbeddableCTA';

enum EmbeddedEntryContentType {
  LinkedImage = 'LinkedImage',
  Cta = 'CTA',
}
type LinkedImageEntry = LinkedImage & {
  contentType: EmbeddedEntryContentType.LinkedImage;
};

type CTA = Cta & {
  contentType: EmbeddedEntryContentType.Cta;
};

export type Entry = LinkedImageEntry | CTA;

const getDocumentOptions = (assets?: Asset[], centerImages?: boolean, entries?: Entry[]) => {
  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="font-semibold">{text}</span>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => (
        <h1 className="mb-4 mt-12 text-3xl font-medium leading-[4.25rem]">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <h2 className="my-4 text-2xl font-medium">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <h3 className="mb-4 mt-8 text-2xl font-medium">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <h4 className="mb-4 mt-6 text-xl font-medium">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <h5 className="my-4 text-lg font-medium">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (_node, children) => (
        <h6 className="my-4 text-base font-medium">{children}</h6>
      ),
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="my-2 text-[length:inherit]">{children}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Link
          className="font-semibold text-teal-700 focus:text-teal-900 focus:underline hover:text-teal-900 hover:underline"
          href={node.data.uri}
          target="_blank"
        >
          {children}
        </Link>
      ),
      [BLOCKS.UL_LIST]: (_node, children) => <ul className="my-4 list-disc pl-10">{children}</ul>,
      [BLOCKS.OL_LIST]: (_node, children) => (
        <ol className="my-4 list-decimal pl-10">{children}</ol>
      ),
      [BLOCKS.QUOTE]: (_node, children) => (
        <blockquote className="my-6 bg-polygons bg-contain bg-left-top bg-no-repeat py-4 pl-9 pr-3 text-base text-black-800">
          {children}
        </blockquote>
      ),
      [BLOCKS.TABLE]: (_node, children) => (
        <div className="my-6 w-0 min-w-full overflow-auto scrollbar-thin scrollbar-track-teal-100 scrollbar-thumb-teal-900 scrollbar-track-rounded scrollbar-thumb-rounded">
          <table className="w-full table-auto border-collapse text-xs sm:table-fixed sm:text-[length:inherit]">
            <tbody>{children}</tbody>
          </table>
        </div>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
        <th className="border border-black-500 bg-gold-50 px-2 py-0 text-left font-semibold sm:px-4 sm:py-0.5">
          {children}
        </th>
      ),
      [BLOCKS.TABLE_CELL]: (_node, children) => (
        <td className="border border-black-500 px-2 py-1 font-normal sm:px-4 sm:py-2">
          {children}
        </td>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const assetId = node.data.target.sys.id;
        const imageAsset = assets?.find(({ sys: { id } }) => id === assetId);

        if (!imageAsset?.contentType?.includes('image')) return <></>;

        return (
          <div className={`flex ${centerImages ? 'justify-center' : 'justify-start'}`}>
            <Image
              alt={imageAsset.title || ''}
              height={imageAsset.height}
              src={imageAsset.url || ''}
              width={imageAsset.width}
            />
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        const entry = entries?.find((entry) => entry.sys.id === node.data.target.sys.id);

        if (!entry) {
          return null;
        }

        switch (entry.contentType) {
          case EmbeddedEntryContentType.LinkedImage:
            return (
              <Link className="block w-fit" href={entry.linkTo || ''}>
                <Image
                  alt={entry.imageAltText || ''}
                  height={entry.image?.height}
                  src={entry.image?.url || ''}
                  width={entry.image?.width}
                />
              </Link>
            );
          case EmbeddedEntryContentType.Cta:
            return (
              <EmbeddableCTA
                buttonLink={entry.buttonLink}
                buttonText={entry.buttonText}
                subtitle={entry.subtitle}
                title={entry.title}
              />
            );
          default:
            return null;
        }
      },
    },
  };

  return options;
};

export default getDocumentOptions;
