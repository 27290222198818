import { BlueCTALink } from 'components/BlueCTALink';

interface Props {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonLink?: string;
}

const EmbeddableCTA = ({ title, subtitle, buttonText, buttonLink }: Props) => {
  return (
    <div className="flex flex-col items-center bg-black-50 p-6">
      <p className="text-2xl font-medium">{title}</p>
      {subtitle && <p>{subtitle}</p>}
      <div className="mt-4">
        <BlueCTALink buttonLink={buttonLink || '/'} buttonText={buttonText || ''} />
      </div>
    </div>
  );
};

export default EmbeddableCTA;
